import React from 'react';
import PropTypes from 'prop-types';
import Rating from '../../Rating/Rating';
import styles from './Card.module.css';
import photoPlaceholder from '../../../../img/no-image-found.png';
import {parsePriceInThousands, GetPriceForItem} from "../../LotMenu/LotMenuUtils";


// eslint-disable-next-line no-confusing-arrow
const Card = ({
  clickHandler,
  color,
  item,
  index,
  mapMode
}) => {
  let photo;
  if (item.photos && mapMode === 'home sites') {
    [photo] = item.photos;
  } else if (item.photos && mapMode !== 'home sites') {
    photo = item.photos[0].getUrl();
  } else {
    photo = photoPlaceholder;
  }

  const getHomesiteDetails = () => {
    switch (item.status) {
      case 'MODELS':
        return `Model Home - ${item.model.MarketingName}`;
      case 'AVAILABLE':
        return 'Available Homesite';
      case 'QUICKMOVEIN':
        return `Available Home - ${item.model.MarketingName}`;
      case 'SOLD':
        return `Sold Home - ${item.model && item.model.MarketingName}`;
      default:
        return '';
    }
  };
  let price = GetPriceForItem(item);

  return (
    <li style={{ borderBottom: '1px solid #D5D9E2' }}>
      <div
        tabIndex={0}
        className={styles.cardContainer}
        onClick={() => {
          clickHandler(item, color);
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            clickHandler(item, color);
          }
        }}
        role="button"
      >
        <div className={styles.cardIndex}>{`${index + 1}.`}</div>
        <img
          className={styles.photo}
          src={item.status === 'AFP' ? item.nhPhoto : photo}
          alt="place"
        />
        <div className={styles.cardDetails}>
          <div
            role="button"
            className={styles.cardTitle}
            tabIndex={0}
          >
            {item.name}
          </div>
          {mapMode !== 'home sites' ? (
            <Rating
              rating={item.rating}
              card
            />
          ) : (
            <div className={styles.homesiteDetails}>{getHomesiteDetails()}</div>
          )}
          {mapMode === 'home sites' && (price) && (
            <span className={styles.price}>
              $
              {price}
              K
            </span>
          )}
        </div>
      </div>
    </li>
  );
};

Card.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  mapMode: PropTypes.string.isRequired
};

export default Card;
