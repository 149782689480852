import amenityCenterIcon from '../img/star-yellow.png';
import salesCenterIcon from '../img/Icon_YOU_ARE_HERE_Large.svg';
import { MarkerClusterer, GridAlgorithm } from "@googlemaps/markerclusterer";

class MarkerUtils {
  constructor(google, map) {
    this.google = google;
    this.map = map;
    this.thing = {
      selected: false
    };
    this.clusterArray = [];
  }

  addCenterMarker = (type, position, i, AmenityCenterSelect) => {
    const markers = [];
    
    let iconType = salesCenterIcon;
    let icon = {
      url: iconType,
      size: new this.google.maps.Size(200, 200),
      anchor: new this.google.maps.Point(10, 10),
      scaledSize: new this.google.maps.Size(30, 30),
    };

    if (type === 'amenity') {
      iconType = amenityCenterIcon;
      icon = {
        url: iconType,
        size: new this.google.maps.Size(20, 20),
        anchor: new this.google.maps.Point(10, 10),
      };
    }

    const marker = new this.google.maps.Marker({
      position,
      map: this.map,
      icon,
      id: i,
    });


    markers.push(marker);

    markers.forEach((thing) => {
      thing.setVisible(true);
      if (type === 'amenity') {
        const obj = thing;
        marker.addListener('click', (e) =>
          AmenityCenterSelect(obj.id));
      }
    });
    return markers;
  }

  turnOffAmenityCenter = (centers) => {
    centers.forEach((center) => (center.setVisible(false)));
  }

  addMarker = (location, clickHandler) => {
    const icon = {
      url: `${process.env.PUBLIC_URL}/img/markers/${location.type}/m1.svg`,
      scaledSize: new this.google.maps.Size(70, 30),
      labelOrigin: new this.google.maps.Point(35.5, 12),
    };

    const marker = new this.google.maps.Marker({
      position: location.place,
      map: this.map,
      label:  { fontSize: '12px', text: `${location.index.toString()}`},
      type: location.type,
      icon
    });
    marker.addListener('click', clickHandler);
    return marker;
  }

  addMarkers = (locations, clickHandler) => {
    const markers = [];
    locations.forEach((location) => {
      const marker = this.addMarker(location, (e) =>
        clickHandler(e, location.color));
      markers.push(marker);
    });
    return markers;
  }

  addMarkersByConfig = (config, places, clickHandler) => {
    let markersByConfig = [];
    const clusterConfig = {};
    config.forEach((configItem) => {
      const locations = places[configItem.name].map((place, i) => {
        const placeObj = {};
        placeObj.color = configItem.color;
        placeObj.label = place.name;
        placeObj.place = place.location;
        placeObj.index = i + 1;
        placeObj.type = place.type;
        return placeObj;
      });
      const markers = this.addMarkers(locations, clickHandler);
      markersByConfig = [...markersByConfig, ...markers];
      if (places[configItem.name].length > 0) {
        clusterConfig[configItem.name] = {
          markerCluster: null,
          markerArray: []
        };
      }
    });

    const markerArrayConfig = Object.keys(clusterConfig)
      .map((key) => clusterConfig[key]);

    markersByConfig.forEach((marker) => {
      marker.setVisible(true);
      clusterConfig[marker.type].markerArray.push(marker);
    });

    markerArrayConfig.forEach((markerGroup, index) => {
      // eslint-disable-next-line no-unused-vars
      const clusterObject = createMarkerClusterer(this.google, this.map, markerGroup);
      markerArrayConfig[index].markerCluster = clusterObject;
      const nameOfGroup = markerGroup.markerArray[0].type;
      markerArrayConfig[index].name = nameOfGroup;
    });
    this.clusterArray = markerArrayConfig;

    return markersByConfig;
  }

  hideMarker = (marker) => {
    marker.setVisible(false);
  }

  hideMarkers = (markers, category) => {
    markers.forEach((marker) => {
      this.hideMarker(marker);
    });
    let selectedCluster = null;
    if (category === 'all') {
      this.clusterArray.forEach((cluster) => {
        cluster.markerCluster.clearMarkers();
      });
    } else {
      selectedCluster = this.clusterArray.find((cluster) =>
        cluster.name === category);
      selectedCluster.markerCluster.clearMarkers();
    }
  }

  showMarker = (marker) => {
    marker.setVisible(true);
  }

  clusterStyles = (iconName) => [
    {
      url: `${process.env.PUBLIC_URL}/img/markers/${iconName}/cluster/m1.png`,
      height: 20,
      width: 20,
    },
    {
      url: `${process.env.PUBLIC_URL}/img/markers/${iconName}/cluster/m2.png`,
      height: 20,
      width: 20,
    }
  ];

  showMarkers = (category) => {
    this.clusterArray.forEach((cluster) => {
      cluster.markerArray.forEach((marker) => {
        marker.setVisible(true);
      });
    });

    if (category === 'all') {
      this.clusterArray.forEach((cluster, index) => {
        const clusterObject = createMarkerClusterer(this.google, this.map, cluster);
        this.clusterArray[index].markerCluster = clusterObject;
      });
    } else {
      const selectedCluster = this.clusterArray.find((cluster) =>
        cluster.name === category);
      const clusterObject = createMarkerClusterer(this.google, this.map, selectedCluster, category);
      selectedCluster.markerCluster = clusterObject;
    }
  }

  clearMarkers = (markers) => {
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    this.clusterArray.forEach((cluster) => {
      cluster.markerCluster.clearMarkers();
    });
  }
}

function createMarkerClusterer(google, map, markerGroup, markerType) {
    return new MarkerClusterer({
        map: map,
        markers: markerGroup.markerArray,
        algorithm: new GridAlgorithm({}),
        renderer: new Renderer(google, markerGroup, markerType)
    })    
}

function getClusterStyles(markerType) {
    return [
        {
            url: `${process.env.PUBLIC_URL}/img/markers/${markerType}/cluster/m1.png`,
            height: 20,
            width: 20,
        },
        {
            url: `${process.env.PUBLIC_URL}/img/markers/${markerType}/cluster/m2.png`,
            height: 20,
            width: 20,
        }
    ];    
}

class Renderer {
    constructor(google, markerGroup, markerType) {
        this.google = google;
        this.markerGroup = markerGroup;
        this.markerType = markerType;
    }
    
    render(cluster) {
        // Code adapted from https://googlemaps.github.io/js-markerclusterer/public/renderers/
        // and from the old implementation.
        const {count, position} = cluster;
        const styles = getClusterStyles(this.markerType ?? this.markerGroup.markerArray[0].type);
        const style = styles[count % styles.length];
        const title = `Cluster of ${count} markers`;
        const zIndex = Number(this.google.maps.Marker.MAX_ZINDEX) + count;
        const clusterOptions = {
            position,
            zIndex,
            title,
            label: {
                text: String(count),
                fontSize: "11px",
                fontWeight: "bold"
            },            
            icon: {
                url: style.url,
                size: { width: style.width, height: style.height }
            },
        };
        return new this.google.maps.Marker(clusterOptions);        
    }
}

export default MarkerUtils;
