import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  Font,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import Canvg from 'canvg';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import styles from './PrintPDF.module.css';
import S3Utils from '../../utils/S3/S3';
import AvenirLight from '../fonts/Avenir-Light.ttf';
import AvenirMedium from '../fonts/Avenir-Roman.ttf';
import GlobalConfig from "../../utils/GlobalConfig";

Font.register({ family: 'Avenir-Light', src: AvenirLight });
Font.register({ family: 'Avenir-Medium', src: AvenirMedium });

const PrintPDF = ({
  auxLotData,
  communityDetails,
  newLotData,
  mapMode,
  handleSelectedLotRID,
  selectedLotRID,
  lotInfo,
  lotType,
  activeProductLine,
  newHomeSelectedLotRID,
  socket,
  roomId,
  listenForPrint,
  activeCollections,
  communityTitle,
  salesCenterData,
  selectedMap,
  lotCollections
}) => {
  const [imgSrc, setImgSrc] = useState(null);

  const getImg = () => {
    const canvas = document.querySelector('#test');
    const imgData = canvas.toDataURL('image/png');
    setImgSrc(imgData);
  };

  const PDFStyles = StyleSheet.create({
    page: {
      backgroundColor: '#fff',
      flexDirection: 'column',
      padding: '2%, 4%',
      fontFamily: 'Avenir-Medium',
      maxWidth: '800px'
    },
    heading: {
      border: '1pt solid #57585a',
      flexDirection: 'row',
      marginBottom: '2pt',
      maxWidth: '700px',
      height: '10%'
    },
    logoContainer: {
      width: '30%',
      height: '100%',
      backgroundColor: 'black',
      justifyContent: 'center',
    },
    headingTextContainer: {
      width: '70%',
      flexDirection: 'column',
      // padding: '0%, 2pt',
      textAlign: 'center',
      height: '100%',
      justifyContent: 'center',
      alignSelf: 'flex-end',
    },
    GVRTitle: {
      fontFamily: 'Avenir-Medium',
      fontSize: '34pt',
      maxWidth: '700px',
      height: '48pt',
      width: '90%',
      alignSelf: 'center',
      position: 'absolute',
      top: '-2px',
      color: '#183463',
    },
    bar: {
      borderBottom: '1pt solid #000',
      height: '1px',
      width: '95%',
      position: 'absolute',
      top: '-1px',
      right: '2.5%'
    },
    communityTitle: {
      fontFamily: 'Avenir-Medium',
      fontSize: '20pt',
      textTransform: 'capitalize',
      alignSelf: 'center',
      width: '100%',
      position: 'absolute',
      top: '-5px',
      color: '#183463',
    },
    address: {
      fontFamily: 'Avenir-Light',
      fontSize: '16pt',
      textTransform: 'capitalize',
      alignSelf: 'center',
      width: '100%',
      position: 'absolute',
      bottom: '-2px',
      color: '#183463',
    },
    headingDetails: {
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '100%',
      height: '45%',
      backgroundColor: 'transparent',
      position: 'absolute',
      bottom: '0px',
    },
    collectionNameText: {
      fontFamily: 'Avenir-Medium',
      fontSize: '22pt',
      fontWeight: '500',
      textTransform: 'uppercase',
      flex: 1,
      alignSelf: 'center',
    },
    headingDetailsSpec: {
      flexDirection: 'row',
      fontFamily: 'Avenir-Light',
      fontSize: '12pt',
      flex: 2,
    },
    displayImgContainer: {
      height: '60%',
      width: '100%'
    },
    displayImg: {
      marginTop: '5pt',
      marginBottom: '5pt',
      height: '95%',
      width: '130%',
      justifyContent: 'center',
      position: 'absolute',
      left: '-15%'
    },
    HSMBar: {
      width: '100%',
      backgroundColor: 'black',
    },
    HSMBarText: {
      fontSize: '20pt',
      fontFamily: 'Avenir-Medium',
      alignSelf: 'center',
      color: 'white',
    },
    key: {
      height: '25%',
      width: '100%',
    },
    keyTitle: {
      fontSize: '20pt',
      fontFamily: 'Avenir-Medium',
      alignSelf: 'left',
      color: 'black',
    },
    keyColorContainer: {
      width: '100%',
      flexDirection: 'row',
      height: '20%',
      justifyContent: 'space-between',
      paddingLeft: '5px',
      marginTop: '6%',
    },
    keyElement: {
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center',
    },
    keyAvailable: {
      height: '3vh',
      width: '3vh',
      backgroundColor: 'green',
      alignSelf: 'center',
      border: '1pt solid black',
      borderRadius: '2pt',
    },
    keySold: {
      height: '3vh',
      width: '3vh',
      backgroundColor: 'red',
      alignSelf: 'center',
      border: '1pt solid black',
      borderRadius: '2pt',
    },
    keyQuick: {
      height: '3vh',
      width: '3vh',
      backgroundColor: 'yellow',
      alignSelf: 'center',
      border: '1pt solid black',
      borderRadius: '2pt',
    },
    keyModel: {
      height: '3vh',
      width: '3vh',
      backgroundColor: '#00b8e6',
      alignSelf: 'center',
      border: '1pt solid black',
      borderRadius: '2pt',
    },
    keyText: {
      fontSize: '20pt',
      fontFamily: 'Avenir-Medium',
      alignSelf: 'center',
      paddingLeft: '5px',
      color: 'black',
    },
    disclaimer: {
      fontFamily: 'Avenir-Light',
      fontSize: '7pt',
      lineHeight: '1.25pt',
      marginBottom: '2pt',
      padding: '0 1%',
      position: 'absolute',
      bottom: '0',
      width: '100%',
    },
    footer: {
      flexDirection: 'row',
      fontFamily: 'Avenir-Light',
      fontSize: '7pt',
      justifyContent: 'space-between',
    }
  });

  const createDocument = () => {
    let collectionInfo = activeProductLine;
    const { communityRID } = salesCenterData.collections[0];
    const currentLocation = communityDetails[communityRID];

    if (collectionInfo === 'all') {
      collectionInfo = '';
    }

    const locationInfo = `${currentLocation.SalesOfficeCity}, ${currentLocation.SalesOfficeState}`;

    return (
      <Document>
        <Page key="1-2" size="A4" style={PDFStyles.page}>
          <View style={PDFStyles.heading}>
            <View style={PDFStyles.logoContainer}>
              <Image src={`${GlobalConfig.get(GlobalConfig.Key.S3_URL)}Sales_Center/${salesCenterData.salesCenterId}/general/logos/Print_Logo.png`} />
            </View>
            <View style={PDFStyles.headingTextContainer}>
              <Text style={PDFStyles.GVRTitle}>{`${communityTitle}`}</Text>
              <View style={PDFStyles.headingDetails}>
                <Text style={PDFStyles.bar} />
                <Text style={PDFStyles.communityTitle}>
                  {`${collectionInfo}`}
                </Text>
                <Text style={PDFStyles.address}>
                  {`${locationInfo}`}
                </Text>
              </View>
            </View>
          </View>
          <View style={PDFStyles.displayImgContainer}>
            <Image style={PDFStyles.displayImg} src={imgSrc} />
          </View>
          <View style={PDFStyles.HSMBar}>
            <Text style={PDFStyles.HSMBarText}>
              Home Site Map
            </Text>
          </View>
          <View style={PDFStyles.key}>
            <Text style={PDFStyles.keyTitle}>
              Home Site Key:
            </Text>
            <View style={PDFStyles.keyColorContainer}>
              <View style={PDFStyles.keyElement}>
                <View style={PDFStyles.keyAvailable} />
                <Text style={PDFStyles.keyText}> Available</Text>
              </View>
              <View style={PDFStyles.keyElement}>
                <View style={PDFStyles.keyQuick} />
                <Text style={PDFStyles.keyText}> Available Home </Text>
              </View>
              <View style={PDFStyles.keyElement}>
                <View style={PDFStyles.keySold} />
                <Text style={PDFStyles.keyText}> Sold </Text>
              </View>
              <View style={PDFStyles.keyElement}>
                <View style={PDFStyles.keyModel} />
                <Text style={PDFStyles.keyText}> Model Home </Text>
              </View>
            </View>
            <View style={PDFStyles.disclaimer}>
              <Text>
                Elevations and floor plan contained herein are not to scale and are
                graphic illustrations for marketing and presentation purposes only.
                Actual floor plans and all materials may vary prior to or during
                construction. Some features shown may be optional. Signature Homes
                reserves the right to substitute materials and components of similar
                quality,and to change features, options, and architectural details
                without prior notice. Accordingly, neither these materials, nor
                any communications made or given in connection with these materials,
                may be deemed to constitute any representation of warranty,
                contract, or guarantee or may otherwise be relied upon by any person
                or entity unless conveyed in written form. Plans, Pricing and
                Features are subject to change without notice. Elevations may show
                options that are not standard. Square footage values and dimensions
                are approximate and may vary.
              </Text>
            </View>
          </View>
          <View style={PDFStyles.footer}>
            <Text>Page 1</Text>
            <Text>{new Date().toLocaleDateString()}</Text>
          </View>
        </Page>
      </Document>
    );
  };

  const toCanvas = async () => {
    const canvas = document.querySelector('#test');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const ctx = canvas.getContext('2d');
    const serializer = new XMLSerializer();
    const svg = document.querySelector('#pdf-lotmap');
    const svgString = serializer.serializeToString(svg);

    const v = await Canvg.fromString(ctx, svgString, { anonymousCrossOrigin: true });
    v.start();
    await v.ready();
    getImg();
  };

  const checkIfFilled = () => {
    const svg = document.getElementById('SVGLoaderElement');
    if (svg) {
      const lotHasFill = newLotData[activeCollections[0]].find((lot) => {
        let hasFill;
        let hasText;
        const lotEl = document.querySelector(`#SVGLoaderElement #_${lot.lotRID}`);
        const lotText = document.querySelector(`#SVGLoaderElement #L_${lot.lotRID}`);
        if (lotEl) {
          hasFill = lotEl.hasAttribute('fill');
        }
        if (lotText && lotText.textContent && lotText.textContent !== 0) {
          hasText = true;
        }
        return (hasFill && hasText);
      });
      if (lotHasFill) {
        setTimeout(() => {
          toCanvas();
        }, 5000);
      } else {
        setTimeout(() => {
          checkIfFilled();
        }, 1000);
      }
    } else {
      setTimeout(() => {
        checkIfFilled();
      }, 1000);
    }
  };

  useEffect(() => {
    checkIfFilled();
  }, []);

  const allLotProxy = lotCollections;

  const handleLotClick = (lot) => {
    if (!['UNKNOWN', 'EMPTY'].includes(lot.status)) {
      handleSelectedLotRID(lot.selector, lot);
      return lot;
    }
    return null;
  };

  const handleFill = (proxy) => {
    if (mapMode !== 'home sites') {
      return '#fff';
    }
    if (activeProductLine === proxy.productLine || activeProductLine === 'all') {
      if (newHomeSelectedLotRID) {
        if (newHomeSelectedLotRID === `#_${proxy.lotRID}`) {
          return proxy.Color;
        }
        return '#fff';
      }
      if (proxy.AvailableModels && lotType === 'AFP') {
        const modelNames = [];
        proxy.AvailableModels.forEach((model) => {
          modelNames.push(model.Key.split('(')[0]);
        });
        const checkLotHasModel = modelNames.includes(lotInfo.name)
          && proxy.selector;
        if (proxy.status === 'AVAILABLE' && checkLotHasModel) {
          return proxy.Color;
        }
      }
      if (selectedLotRID && selectedLotRID === `#_${proxy.lotRID}`) {
        return proxy.Color;
      }
      if (!selectedLotRID) {
        return proxy.Color;
      }
    }

    return '#cecece';
  };

  const listLots = () => allLotProxy.map((proxy) => (
    <SvgProxy
      key={proxy.selector}
      selector={proxy.selector}
      fill={handleFill(proxy)}
      stroke="black"
      onClick={() => handleLotClick(proxy)}
      className={styles.lots}
      id={proxy.selector}
    />
  ));

  const getActiveLine = allLotProxy.filter((active) => {
    if (activeProductLine === 'all') {
      return active;
    }
    return active.productLine === activeProductLine;
  });

  const listText = () => getActiveLine.map((proxy) => (
    <SvgProxy
      key={proxy.textSelector}
      selector={`${proxy.textSelector} text`}
      stroke={proxy.enabled ? 'black' : '#cecece'}
      onClick={() => handleLotClick(proxy)}
      visibility="visible"
    >
      {`${proxy.lotNum}`}
    </SvgProxy>
  ));

  const fillAuxLots = () => Object.values(auxLotData).flat().map((lot) => (
    <SvgProxy
      key={lot.LotRID}
      selector={`#_${lot.LotRID}`}
      fill="#cecece"
    />
  ));

  const disableLotCircles = () => allLotProxy.map((proxy) => (
    <SvgProxy
      key={`${proxy.textSelector}C`}
      selector={`${proxy.textSelector} circle`}
      visibility="hidden"
    />
  ));

  const disableAuxLotCircles = () => Object.values(auxLotData)
    .flat()
    .map((lot) => (
      <SvgProxy
        key={`${lot.LotRID}C`}
        selector={`#L_${lot.LotRID} circle`}
        visibility="hidden"
      />
    ));

  const disableAuxLotText = () => Object.values(auxLotData)
    .flat()
    .map((lot) => (
      <SvgProxy
        key={`${lot.LotRID}T`}
        selector={`#L_${lot.LotRID} text`}
        visibility="hidden"
      />
    ));

  const renderLotmap = () => {
    let SVGPath = '';

    if (!selectedMap && salesCenterData.lotMaps.length > 1) {
      SVGPath = `${GlobalConfig.get(GlobalConfig.Key.S3_URL)}Sales_Center/${salesCenterData.salesCenterId}/LotMap_Print_${salesCenterData.lotMaps[0].mapNumber}.svg?origin=bimaire.app`;
    } else if (selectedMap && salesCenterData.lotMaps.length > 1) {
      SVGPath = `${GlobalConfig.get(GlobalConfig.Key.S3_URL)}Sales_Center/${salesCenterData.salesCenterId}/LotMap_Print_${selectedMap.mapNumber}.svg?origin=bimaire.app`;
    } else {
      SVGPath = `${GlobalConfig.get(GlobalConfig.Key.S3_URL)}Sales_Center/${salesCenterData.salesCenterId}/LotMap_Print.svg?origin=bimaire.app`;
    }
    return (
      <svg id="pdf-lotmap">
        <SvgLoader id="SVGLoaderElement" path={`${SVGPath}`}>
          {auxLotData && fillAuxLots()}
          {auxLotData && disableAuxLotCircles()}
          {auxLotData && disableAuxLotText()}
          {disableLotCircles()}
          {listText()}
          {listLots()}
        </SvgLoader>
      </svg>
    );
  };

  const uploadPDF = async (file) => {
    const key = `docs/homesite-${roomId}.pdf`;
    const s3 = S3Utils.connect();
    await S3Utils.putObject('application/pdf', file, key, s3);

    return key;
  };

  // uncomment const fileName, socket.emit

  const sendSocketInfo = async (file) => {
    try {
      const fileName = await uploadPDF(file);
      const fileLink = `https://${GlobalConfig.get(GlobalConfig.Key.S3_BUCKET)}.s3-${GlobalConfig.get(GlobalConfig.Key.S3_REGION)}.amazonaws.com/${fileName}`;

      const event = {
        data: fileLink,
        name: 'open pdf',
        roomId
      };
      socket.emit('open pdf', event);
      listenForPrint(event);
    } catch (err) {
      // TODO: Replace console.error() with reusable Alert/Error component
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <div className={styles.PrintPDF}>
      <div className={styles.wrapper}>
        {renderLotmap()}
        {imgSrc && (
          <div className={styles.imgWrapper}>
            <img src={imgSrc} alt="lotmap" id="lotmapImage" />
            {document.getElementById('pdf-lotmap').lastChild.hasChildNodes && (
              <PDFDownloadLink document={createDocument()}>
                {/* url parameter */}
                {/* {({ blob, loading, url }) => { */}

                {({ blob, loading }) => {
                  if (!loading) {
                    const file = new File([blob], 'homesite.pdf');
                    // open in window - for testing
                    // window.open(url);
                    sendSocketInfo(file);
                    return true;
                  }

                  return '';
                }}
              </PDFDownloadLink>
            )}
          </div>
        )}
        <canvas id="test" />
      </div>
      <div className={styles.pdfLoading}>
        Building Your Personalized Brochure
      </div>
    </div>
  );
};

PrintPDF.defaultProps = {
  newLotData: null,
  lotInfo: null,
  lotType: null,
  selectedLotRID: null,
  newHomeSelectedLotRID: null,
};

PrintPDF.propTypes = {
  newLotData: PropTypes.arrayOf(PropTypes.object),
  mapMode: PropTypes.string.isRequired,
  pinsActive: PropTypes.bool.isRequired,
  handleSelectedLotRID: PropTypes.func.isRequired,
  selectedLotRID: PropTypes.string,
  lotInfo: PropTypes.object,
  lotType: PropTypes.string,
  newHomeSelectedLotRID: PropTypes.string,
  activeProductLine: PropTypes.string.isRequired,
  activeCollections: PropTypes.array.isRequired,
  communityTitle: PropTypes.string.isRequired,
  salesCenterData: PropTypes.object.isRequired,
  lotStatusConfig: PropTypes.object.isRequired,
  newLocData: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default PrintPDF;
