import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Submenu.module.css';
import Dropdown from '../Dropdown/Dropdown';
import LocationDetails from '../../../shared/LocationDetails/LocationDetails';
import LotMapUtils from '../../../utils/lotMapUtils';
import availCardImg from '../../../img/IMG_Available_Card.png';
import homeCardImg from '../../../img/IMG_Home_Card.png';
import ProductLine from '../ProductLine/ProductLine';
import LotMenu from '../LotMenu/LotMenu';
import { setInitialLotData, setInitialModelData } from './activeCollectionUtils'

const Submenu = ({
  center,
    isBeingControlled,
  community,
  directionsActive,
  directionsUtils,
  handleDirectionsReset,
  hidePlaces,
  listType,
  mapMode,
  places,
  placesConfig,
  poi,
  poiConfig,
  selectedPlace,
  setSelectedPlace,
  showPlaces,
  toggleDirectionsActive,
  roomId,
  socket,
  map,
  google,
  photoGalleryActive,
  togglePhotoGallery,
  state,
  handleSelectedLotRID,
  clearSelectedLot,
  lotInfo,
  lotType,
  showOptionList,
  handleOptionList,
  showAvailModel,
  handleShowAvailModel,
  productLineMenu,
  handleProductLine,
  activeProductLine,
  toggleProductLineMenu,
  productLineText,
  newLotData,
  lotCollections,
  newModelData,
  activeCollections,
  selectedCommunityRID,
  salesCenterData,
  lotStatusConfig,
  triggerMatterport,
  activeMatterport,
  matterportConfig,
  selectedLotRID,
  splash,
  builderSelected,
  selectedMap,
  changeSelectedMap,
  directionCenter
}) => {
  const [selectedModel, setSelectedModel] = useState(null);

  useEffect(() => {
    if (selectedLotRID && !selectedLotRID.toString().includes('#')) {
      setSelectedModel({
        basePrice: lotInfo.price,
        communityRID: lotInfo.communityRID,
        communityName: lotInfo.communityName,
        descrBath: lotInfo.bath,
        descrBedrooms: lotInfo.bed,
        descrSqf: lotInfo.sqf,
        marketingName: lotInfo.marketingName,
        modelRID: lotInfo.id
      });
    }
  }, [selectedLotRID]);

  /**
   * setting the active models and active data is done through the
   * utils file: activeCollectionUtils.js.
   * The essential purpose is the find a present the pertinant models and
   * lots into the submenu. this will changed on which active collection and
   * product line are selected
   *
   * params: salesCenterData - sales center object data.
   *    newModelData - all models.
   *    newLotData - all lots.
   *    activeCollections - selected active RID, default to all rids in an array
   *    activeProductLine - selected product line, 'all'
   *    selectedCommunityRID - selected RID
   */
  const activeModelData = setInitialModelData(salesCenterData, newModelData, activeCollections, selectedCommunityRID);

  const activeLotData = setInitialLotData(salesCenterData, newLotData, activeCollections, activeProductLine);


  /**
   * sets the homesitesConfig. AFP is set based on activeModelData set in
   * setInitialModelData(), the rest are pushed based upon iterating though
   * activeLotData set by setInitialLotData().
   *
   * AFP: available floor plans, is set based on modelData, which is formatted in
   *    src/App/formatUtils.js in formatModelData()
   * AVAILABLE: available lots
   * QUICKMOVEIN: QMI lots
   * MODELS: lots that are model homes
   * SOLD: lots that are sold
   *
   * activeLotData - filtered newLotData
   * activeModelData - filtered newModelData
   *
   * returns homesitesConfig[]
   */
  const generateHomesitesConfig = () => {
    const newHomesConfig = [...activeModelData];

    const homesitesConfig = [
      {
        id: 'AFP',
        name: 'floor plans',
        color: lotStatusConfig.AVAILABLE.color,
        items: [...newHomesConfig],
        availHomes: [],
      },
      {
        id: 'AVAILABLE',
        name: 'available',
        color: lotStatusConfig.AVAILABLE.color,
        items: []
      },
      {
        id: 'QUICKMOVEIN',
        name: 'available Homes',
        color: lotStatusConfig.QUICKMOVEIN.color,
        items: []
      },
      {
        id: 'MODELS',
        name: 'models',
        color: lotStatusConfig.MODELS.color,
        items: []
      },
      {
        id: 'SOLD',
        name: 'sold',
        color: lotStatusConfig.SOLD.color,
        items: []
      }
    ];
    activeLotData.forEach((lot, index) => {
      const config = homesitesConfig.find((category) =>
        category.id === lot.status);

      if (config) {
        if (config.name === 'available') {
          lot.photos.push(availCardImg);
        } else {
          lot.photos.push(homeCardImg);
        }
        if (lot.Model) {
          lot.model = lot.Model;
          if (!lot.Model.MarketingName) {
            lot.model.MarketingName = lot.model.Name;
          }
        }
        config.items.push(lot);
      }
    });
    return homesitesConfig;
  };

  const homesitesConfig = generateHomesitesConfig();

  const run2DIFPEvent = () => {
    socket.emit('2D IFP', {
      name: '2D IFP',
      roomId,
      data: selectedModel
    });
  };

  const handleHomesiteItemClick = (item) => {
    if (item.status === 'AFP') {
      setSelectedModel({
        basePrice: item.price,
        communityName: item.communityName,
        communityRID: item.communityRID,
        descrBath: item.bath,
        descrBedrooms: item.bed,
        descrSqf: item.sqf,
        marketingName: item.marketingName,
        modelRID: item.id,

      });
      handleSelectedLotRID(`${item.id}`, item);
    } else {
      handleSelectedLotRID(`#_${item.id}`, item);
    }
  };

  const listDropdowns = () => {
    switch (mapMode) {
      case 'surrounding area':
        // eslint-disable-next-line no-confusing-arrow
        return placesConfig.map((category, i) => {
          return places[category.name].length > 0
            ? (
              <Dropdown
                categoryKey={category.name}
                color={category.color}
                key={category.name}
                heading={category.name}
                hidePlaces={hidePlaces}
                itemClickHandler={setSelectedPlace}
                items={places[category.name]}
                index={i}
                listType={listType}
                mapMode={mapMode}
                showPlaces={showPlaces}
              />
            ) : (
              null
            );
        });
      case 'points of interest':
        return poiConfig.map((category, i) => {
          const heading = category.name === 'community' ? community.locationName : state.name;
          return (
            <Dropdown
              categoryKey={category.name}
              color={category.color}
              key={category.name}
              heading={heading}
              hidePlaces={hidePlaces}
              itemClickHandler={setSelectedPlace}
              items={poi[category.name]}
              listType={listType}
              mapMode={mapMode}
              showPlaces={showPlaces}
              index={i}
            />
          );
        });
      case 'home sites':
        // eslint-disable-next-line no-confusing-arrow
        return homesitesConfig.map((category, i) => {
          return category.items.length > 0
            ? (
              <Dropdown
                categoryKey={category.name}
                color={category.color}
                checkbox={false}
                key={category.name}
                heading={category.name}
                hidePlaces={hidePlaces}
                itemClickHandler={(item) => handleHomesiteItemClick(item)}
                items={category.items}
                listType={listType}
                mapMode={mapMode}
                showPlaces={showPlaces}
                lotInfo={lotInfo}
                index={i}
              />
            ) : (
              null
            );
        });
      default:
        return null;
    }
  };

  const handleDirectionsClick = (end, travelMode) => {
    toggleDirectionsActive();
    if (selectedMap) {
      const centerLocation = new google.maps.LatLng(
        selectedMap.center[0], selectedMap.center[1]
      );
      directionsUtils.calcRoute(centerLocation, end, travelMode);
    } else {
      let directionCenter = null;
      if(salesCenterData.lotMaps.length > 1) {
        directionCenter = new google.maps.LatLng(
          salesCenterData.lotMaps[1].center[0], salesCenterData.lotMaps[1].center[1],
        );
      } else {
        directionCenter = center;
      }
      directionsUtils.calcRoute(directionCenter, end, travelMode);
    }
  };

  const homeSiteRouter = () => {
    if(salesCenterData.lotMaps.length > 1 && mapMode ==='home sites' && !selectedMap) {
      return salesCenterData.lotMaps.map((map, index) => {
        if(index === 0) {
          return (
            <div className={styles.filingOne} onClick={() => {changeSelectedMap(salesCenterData.lotMaps[index])}}>
              <div className={styles.filingText}>
                {`Filing ${map.mapNumber}`}
              </div>
            </div>
          )
        }
        return (
          <div className={styles.filing} onClick={() => {changeSelectedMap(salesCenterData.lotMaps[index])}}>
           <div className={styles.filingText}>
              {`Filing ${map.mapNumber}`}
            </div>
          </div>
        )
      })
    }

    if (productLineMenu) {
      return (
        <ProductLine
          handleProductLine={handleProductLine}
          toggleProductLineMenu={toggleProductLineMenu}
          activeCollections={activeCollections}
          salesCenterData={salesCenterData}
          activeProductLine={activeProductLine}
          selectedCommunityRID={selectedCommunityRID}
        />
      );
    }
    return lotType
      ? (
        <LotMenu
          clearSelectedLot={clearSelectedLot}
          lotType={lotType}
          lotInfo={lotInfo}
          handleOptionList={handleOptionList}
          showOptionList={showOptionList}
          showAvailModel={showAvailModel}
          handleShowAvailModel={handleShowAvailModel}
          homesitesConfig={homesitesConfig}
          activeModelData={activeModelData}
          lotStatusConfig={lotStatusConfig}
          run2DIFPEvent={run2DIFPEvent}
          salesCenterData={salesCenterData}
          setSelectedModel={setSelectedModel}
          activeProductLine={activeProductLine}
          triggerMatterport={triggerMatterport}
          activeMatterport={activeMatterport}
          matterportConfig={matterportConfig}
          selectedModel={selectedModel}
          roomId={roomId}
        />
      )
      : listDropdowns();
  };

  const handleHeader = () => {
    if(splash && !builderSelected) {
      return 'SELECT A BUILDER';
    }
    if(salesCenterData.lotMaps.length > 1 && mapMode ==='home sites' && !selectedMap) {
      return 'SELECT A HOMESITE MAP';
    }
    return productLineText;
  }

  if (isBeingControlled && !selectedPlace) {
    return null;
  }
  return (
    <div className={`${styles.Submenu} ${styles.unselectable}`}>
      {mapMode === 'home sites'
      && (
      <div className={styles.collectionHeader}>
        {handleHeader()}
      </div>
      )}
      <div className={styles.content}>
        {{
          community: <div>community</div>,
          'home sites': (
            <>
              {splash && !builderSelected ? null : homeSiteRouter()}
            </>
          ),
          'surrounding area': (
            <>
              {!selectedPlace ? listDropdowns() : (
                <LocationDetails
                  directionsActive={directionsActive}
                  directionsUtils={directionsUtils}
                  handleDirectionsClick={handleDirectionsClick}
                  handleDirectionsReset={handleDirectionsReset}
                  location={selectedPlace}
                  setSelectedPlace={setSelectedPlace}
                  map={map}
                  google={google}
                  photoGalleryActive={photoGalleryActive}
                  togglePhotoGallery={togglePhotoGallery}
                />
              )}
            </>
          ),
          'points of interest': (
            <>
              {!selectedPlace ? listDropdowns() : (
                <LocationDetails
                  directionsActive={directionsActive}
                  directionsUtils={directionsUtils}
                  handleDirectionsClick={handleDirectionsClick}
                  handleDirectionsReset={handleDirectionsReset}
                  location={selectedPlace}
                  setSelectedPlace={setSelectedPlace}
                  map={map}
                  google={google}
                  photoGalleryActive={photoGalleryActive}
                  togglePhotoGallery={togglePhotoGallery}
                />
              )}
            </>
          )
        }[mapMode]}
      </div>
    </div>
  );
};

Submenu.defaultProps = {
  community: null,
  places: null,
  poi: null,
  selectedPlace: null,
  showAvailModel: null,
  lotType: null,
  lotInfo: null,
};

Submenu.propTypes = {
  center: PropTypes.object.isRequired,
  google: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  listType: PropTypes.string.isRequired,
  community: PropTypes.object,
  directionsActive: PropTypes.bool.isRequired,
  directionsUtils: PropTypes.object.isRequired,
  handleDirectionsReset: PropTypes.func.isRequired,
  hidePlaces: PropTypes.func.isRequired,
  mapMode: PropTypes.string.isRequired,
  photoGalleryActive: PropTypes.bool.isRequired,
  places: PropTypes.object,
  placesConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  poi: PropTypes.object,
  poiConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPlace: PropTypes.object,
  setSelectedPlace: PropTypes.func.isRequired,
  showPlaces: PropTypes.func.isRequired,
  toggleDirectionsActive: PropTypes.func.isRequired,
  togglePhotoGallery: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  showAvailModel: PropTypes.string,
  handleShowAvailModel: PropTypes.func.isRequired,
  handleOptionList: PropTypes.func.isRequired,
  showOptionList: PropTypes.bool.isRequired,
  lotType: PropTypes.string,
  lotInfo: PropTypes.object,
  clearSelectedLot: PropTypes.func.isRequired,
  handleSelectedLotRID: PropTypes.func.isRequired,
  roomId: PropTypes.string,
  socket: PropTypes.object,
  productLineMenu: PropTypes.bool.isRequired,
  handleProductLine: PropTypes.func.isRequired,
  newLotData: PropTypes.object.isRequired,
  newModelData: PropTypes.object.isRequired,
  activeProductLine: PropTypes.string.isRequired,
  toggleProductLineMenu: PropTypes.func.isRequired,
  productLineText: PropTypes.string.isRequired,
  activeCollections: PropTypes.array.isRequired,
  salesCenterId: PropTypes.number.isRequired,
  salesCenterData: PropTypes.object.isRequired,
  lotStatusConfig: PropTypes.object.isRequired,
  selectedCommunityRID: PropTypes.string.isRequired,
  triggerMatterport: PropTypes.func.isRequired,
  activeMatterport: PropTypes.bool.isRequired,
  matterportConfig: PropTypes.object.isRequired,
  selectedLotRID: PropTypes.string.isRequired
};

export default Submenu;
