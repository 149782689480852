import React from 'react';
import PropTypes from 'prop-types';
import styles from './LotMenu.module.css';
import BackArrow from '../../../img/backArrow.svg';
import sqFtIcon from '../../../img/square_foot_icon.svg';
import sqFtIconB from '../../../img/square_foot_icon_B.svg';
import bedIcon from '../../../img/Bed_Icon.svg';
import bedIconB from '../../../img/Bed_Icon_B.svg';
import showerIcon from '../../../img/Shower_Icon.svg';
import showerIconB from '../../../img/Shower_Icon_B.svg';
import Arrow from '../../../img/Right-Arrow.svg';
import MatterPort from '../../../utils/MatterPort';
import Exit from '../../../img/Button_Exit.png';
import modelHomeIcon from '../../../img/model-home-icon.svg';
import {getModelDetails, parsePriceInThousands} from "./LotMenuUtils";




const getBlockList = (homesitesConfig, lotInfo) => {
  const availableLots = homesitesConfig.find((obj) => obj.id === 'AVAILABLE');
  const blockList = [];
  availableLots.items.forEach((lot) => {
    const availModels = [];
    if (lot.AvailableModels) {
      lot.AvailableModels.forEach((model) => {
        if (model.Key.includes('(')) {
          availModels.push(model.Key.split('(')[0]);
        } else if (model.Key.includes('/')) {
          availModels.push(model.Key.split('/')[0]);
        } else {
          availModels.push(model.Key);
        }
      });
    }
    if (availModels.includes(lotInfo.name)) {
      const selectedLotInfo = {};
      selectedLotInfo.Name = lot.name;
      selectedLotInfo.lotRID = `#_${lot.id}`;
      selectedLotInfo.keyIndex = lot.id;
      selectedLotInfo.communityRID = lot.communityRID;
      if(lot.lotPremium) {
        selectedLotInfo.lotPremiumInThousands = parsePriceInThousands(lot.lotPremium);
      }
      selectedLotInfo.lotPremium = lot.lotPremium;
      selectedLotInfo.Handing = lot.Handing;
      blockList.push(selectedLotInfo);
    }
  });

  return blockList;
};

const LotMenu = ({
  clearSelectedLot,
  lotType,
  lotInfo,
  handleOptionList,
  showOptionList,
  showAvailModel,
  handleShowAvailModel,
  homesitesConfig,
  activeModelData,
  lotStatusConfig,
  run2DIFPEvent,
  salesCenterData,
  selectedModel,
  setSelectedModel,
  activeProductLine,
  triggerMatterport,
  activeMatterport,
  matterportConfig,
  newModelData,
  roomId
}) => {

  const blockList = getBlockList(homesitesConfig, lotInfo);

  const modelDetails = getModelDetails(lotInfo, activeModelData);

  const selectedAvailModelData = showAvailModel
        && modelDetails.find((model) =>
          model.Name === showAvailModel);

  const selectedDefaultModelData = lotInfo.model
    && modelDetails.find((model) => {
      let didMatch = false;
      if (didMatch === false) {
        didMatch = model.marketingName === lotInfo.model.MarketingName;
      }
      if (didMatch === false) {
        didMatch = model.Name === lotInfo.model.Name;
      }
      return didMatch;
    });

  const menuConfig = () => {
    const obj = {};

    switch (lotType) {
      case 'AFP': {
        const theModel = modelDetails.find((model) =>
          model.Name === lotInfo.name.trim());

        obj.color = showAvailModel ? lotStatusConfig.AVAILABLE.color : '#000';
        obj.title = lotInfo.MarketingName;
        obj.subTitle = showAvailModel ?? "Homesite N/A";
        if(lotInfo.price){
          let numericalPrice = parsePriceInThousands(lotInfo.price);
          if(showAvailModel) {
            const selectedLot = blockList.find((lot) => lot.Name === showAvailModel.trim());
            if (selectedLot && selectedLot.lotPremiumInThousands && selectedLot.lotPremiumInThousands > 0) {
              numericalPrice += selectedLot.lotPremiumInThousands;
              obj.lotPremiumString = selectedLot.lotPremium;
              obj.Handing = selectedLot.Handing
            }
          }
          obj.price = numericalPrice;
        }
        obj.photo = theModel.photo || '';
        obj.sqf = `${theModel.sqf || ''}`;
        obj.bed = theModel.bed || '';
        obj.bath = theModel.bath || '';
        obj.list = [...blockList];
        break;
      }
      case 'AVAILABLE': {
        obj.color = lotStatusConfig.AVAILABLE.color;
        obj.title = !showAvailModel ? 'Available' : showAvailModel;
        obj.subTitle = lotInfo.name;
        obj.price = showAvailModel && selectedAvailModelData.price;
        obj.lotPremiumString = lotInfo.lotPremium;
        obj.photo = showAvailModel && selectedAvailModelData.photo;
        obj.sqf = !showAvailModel ? 'n/a' : `${selectedAvailModelData.sqf}`;
        obj.bed = !showAvailModel ? 'n/a' : selectedAvailModelData.bed;
        obj.bath = !showAvailModel ? 'n/a' : selectedAvailModelData.bath;
        obj.list = [...modelDetails];
        obj.communityRID = lotInfo.communityRID;
        obj.Handing = lotInfo.Handing;
        break;
      }
      case 'MODELS': {
        obj.color = lotStatusConfig.MODELS.color;
        obj.title = lotInfo.model.MarketingName;
        obj.subTitle = lotInfo.name;
        obj.price = null;
        obj.photo = selectedDefaultModelData.photo;
        obj.sqf = `${lotInfo.model.DescrSqf}`;
        obj.bed = selectedDefaultModelData.bed;
        obj.bath = selectedDefaultModelData.bath;
        obj.list = null;
        break;
      }
      case 'QUICKMOVEIN': {
        obj.color = lotStatusConfig.QUICKMOVEIN.color;
        obj.title = lotInfo.model.MarketingName ? lotInfo.model.MarketingName : lotInfo.model.Name;
        obj.subTitle = lotInfo.name;
        if(!isNaN(parseFloat(lotInfo.QuickPrice)) && isFinite(lotInfo.QuickPrice)){ //check if QuickPrice is number
          obj.price = Math.round(lotInfo.QuickPrice / 1000  * 10) / 10; //price in thousands rounded to hundreds
        }
        obj.lotPremiumString = lotInfo.lotPremium;
        obj.photo = selectedDefaultModelData.photo;
        obj.sqf = `${lotInfo.QuickSQF}`;
        //this needs to be changed to custom fields
        obj.bed = lotInfo.QuickBeds;
        obj.bath = lotInfo.QuickBaths;
        obj.list = null;
        obj.Handing = lotInfo.Handing;

        if(lotInfo.ExtraMarketHomeData){
          obj.JobStage = lotInfo.ExtraMarketHomeData.JobStage;
          obj.SelectedOptValue = lotInfo.ExtraMarketHomeData.SelectedOptValue;
          obj.Handing = lotInfo.ExtraMarketHomeData.Handing;
        }

        break;
      }
      case 'SOLD': {
        if (selectedDefaultModelData) {
          obj.color = lotStatusConfig.SOLD.color;
          obj.title = lotInfo.model.MarketingName ? lotInfo.model.MarketingName : lotInfo.model.Name;
          obj.subTitle = lotInfo.name;
          obj.price = null;
          obj.photo = selectedDefaultModelData.photo;
          obj.sqf = `${lotInfo.model.DescrSqf}`;
          obj.bed = selectedDefaultModelData.bed;
          obj.bath = selectedDefaultModelData.bath;
          obj.list = null;
        } else {
            obj.color = lotStatusConfig.SOLD.color;
            obj.title = lotInfo.model ? (lotInfo.model.MarketingName ? lotInfo.model.MarketingName : lotInfo.model.Name) : "N/A";
            obj.subTitle = lotInfo.name;
            obj.price = null;
            obj.photo = lotInfo.photos[0];
            if(lotInfo.model) {
              obj.sqf = `${lotInfo.model.DescrSqf}`;
              obj.bed = `${lotInfo.model.DescrBaths}`;
              obj.bath = `${lotInfo.model.DescrBaths}`;
            }
            obj.list = null;
        }
        break;
      }
      default:
        console.error('lot type error');
    }

    return obj;
  };

  const menuConfigObj = menuConfig();

  const modelSpecs = () => {
    const specConfig = [
      {
        icon: salesCenterData.isActiveAdult ? bedIconB : bedIcon,
        spec: menuConfigObj.bed,
      },
      {
        icon: salesCenterData.isActiveAdult ? showerIconB : showerIcon,
        spec: menuConfigObj.bath,
      },
      {
        icon: salesCenterData.isActiveAdult ? sqFtIconB : sqFtIcon,
        spec: menuConfigObj.sqf,
      },
    ];

    return specConfig.map((spec) => (
      <div className={styles.specDetail} key={spec.icon}>
        <img src={spec.icon} alt="spec" />
        <div className={styles.specNumber}>{spec.spec}</div>
      </div>
    ));
  };

  const matterportLink = MatterPort.getMatterportLink(
    lotInfo.communityRID,
    selectedModel ? selectedModel.modelRID : lotInfo.id,
    matterportConfig
  );

  const menuButtons = () => {
    const config = [
      {
        disabled: false,
        handler: () => handleOptionList(),
        text: 'Homesites',
        id: 1,
      },
      {
        disabled: !matterportLink,
        handler: () => triggerMatterport(),
        text: 'Virtual Tour',
        id: 2,
      },
      {
        disabled: false,
        handler: () => run2DIFPEvent(lotInfo, selectedAvailModelData),
        text: 'Personalize',
        id: 3,
      }
    ];

    let buttonFilter = showAvailModel
      ? config.filter((button) => [2, 3].includes(button.id))
      : config;

    if (!roomId) {
      buttonFilter = buttonFilter.filter((button) => button.id !== 3);
    }

    return (
      <div className={styles.buttonContainer}>
        {buttonFilter.map((button) => (
          <button
            className={styles.menuButton}
            disabled={button.disabled}
            onClick={button.handler}
            key={button.id}
            type="button"
          >
            {button.text}
          </button>
        ))}
      </div>
    );
  };

  const handleOptionClick = (opt) => {
    console.log(opt)
    if (!opt.lotRID) {
      const model = activeModelData.find((modelData) =>
        modelData.MarketingName === opt.Name);
      setSelectedModel({
        basePrice: model.BasePrice,
        communityName: model.communityName,
        communityRID: model.communityRID,
        descrBath: model.DescrBaths,
        descrBedrooms: model.DescrBedrooms,
        descrSqf: model.DescrSqf,
        marketingName: model.MarketingName,
        modelRID: model.ModelRID
      });
    }
    handleShowAvailModel(opt.Name, opt.lotRID);
  };

  const optionList = () => {
    const arrayToPresent = [];

    // Build the list of models to exclude
    const listToExclude = [];
    if (salesCenterData.excludedModels) {
      salesCenterData.excludedModels.forEach((exclusion) => {
        if (exclusion.hasOwnProperty('Name')) {
          listToExclude.push(exclusion.Name);
        }
      });
    }

    // Trim list of available models based on exclusion list
    if (lotInfo.AvailableModels) {
      const availableModels = [];
      lotInfo.AvailableModels.forEach((availModel) => {
        if (!listToExclude.includes(availModel.Key)) {
          availableModels.push(availModel);
        }
      });

      // Build list of models that can be read and displayed to menu
      availableModels.forEach((availModel) => {
        menuConfigObj.list.forEach((menuModel) => {
          if (availModel.Key.includes(menuModel.Name)) {
            arrayToPresent.push(menuModel);
          }
        });
      });
    } else {
      menuConfigObj.list.forEach((opt) => {
        if (lotInfo.communityRID === opt.communityRID) {
          arrayToPresent.push(opt);
        }
      });
    }

    return (
      <div className={styles.optionList}>
        {arrayToPresent.map((opt) => (
          <div
            className={styles.optionRow}
            onClick={() => handleOptionClick(opt)}
            role="button"
            tabIndex={0}
            key={opt.keyIndex}
          >
            <div>
              {opt.Name}
            </div>
            {(opt.price > 0 || opt.lotPremiumInThousands > 0) && (
              <div>
                {`$${opt.price ?? opt.lotPremiumInThousands}K`}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const handleMenuFlow = () => {
    if (lotType === 'AFP') {
      if (showAvailModel) {
        return menuButtons();
      }
      if (showOptionList) {
        return optionList();
      }
      return menuButtons();
    }

    if (lotType === 'AVAILABLE') {

      return !showAvailModel ? optionList() : menuButtons();
    }
    return null;
  };

  const showMatterport = () => (
    <div className={styles.frameBackground}>
        <iframe className={activeMatterport ? styles.frameShown : styles.frameHidden} title="matterport" src={matterportLink}/>
        <button
          className={styles.matterportExit}
          type="button"
          onClick={() => triggerMatterport() }
        >
          <img src={Exit} alt="back" />
        </button>
    </div>
  );

  const renderLotTypeInfo = () => {
    if (['AFP', 'AVAILABLE'].includes(lotType) && !showAvailModel) return null;
    let lotTypeText;
    switch (lotType) {
      case 'AFP':
        lotTypeText = 'Available';
        break;
      case 'AVAILABLE':
        lotTypeText = 'Available';
        break;
      case 'QUICKMOVEIN':
        lotTypeText = 'Available Home';
        break;
      case 'MODELS':
        lotTypeText = 'Model';
        break;
      case 'SOLD':
        lotTypeText = 'Not Available';
        break;
      default:
        console.error(`lotType not recognized: ${lotType}`);
    }
    const lotTypeColor = homesitesConfig.find((homesiteType) =>
      homesiteType.id === lotType).color;

    return (
        <div className={styles.simplifiedLotTypeInfo}style={{ backgroundColor: lotTypeColor }}>{lotTypeText}</div>
    );
  };

  const renderExtraLotMarketHomeInfo = () => {
    if(!menuConfigObj.lotPremiumString &&
        !menuConfigObj.Handing &&
        !menuConfigObj.JobStage &&
        !menuConfigObj.SelectedOptValue
    )  {
      return;
    }

    return <div class={styles.extraMarketHomeData}>
      {menuConfigObj.lotPremiumString && <div>Lot Premium: {menuConfigObj.lotPremiumString}</div>}
      {menuConfigObj.Handing &&  <div>Garage: {menuConfigObj.Handing}</div>}
      {menuConfigObj.JobStage && <div>Stage: {menuConfigObj.JobStage}</div>}
      {menuConfigObj.SelectedOptValue && <div>Color Scheme: {menuConfigObj.SelectedOptValue}</div>}
    </div>
  }

  const handleBackClick = () => {
    if (showAvailModel) {
      setSelectedModel(null);
      clearSelectedLot(true);
    } else {
      clearSelectedLot();
    }
  };

  return (
    <div className={styles.lotMenu}>
      {lotType === 'AVAILABLE' && !showAvailModel
          ? null
          : (<>
              <img
                  className={styles.lotPhoto}
                  src={menuConfigObj.photo}
                  alt="lotPhoto"
              />
              {renderLotTypeInfo()}
              </>
          )}
      <div className={styles.lotHeader}>
        <div>
          <div>
            <h2>{menuConfigObj.title}</h2>
            <h3>{menuConfigObj.subTitle}</h3>
          </div>
          <div>
            {menuConfigObj.price && (
              <div className={styles.price}>${menuConfigObj.price}K</div>
            )}
            {menuConfigObj.price && (lotType === 'AFP' || lotType === 'AVAILABLE') && (
              <div>Starting at</div>
            )}
            {menuConfigObj.price && (lotType === 'QUICKMOVEIN') && (
                <div>Price</div>
            )}
          </div>
        </div>
        {lotType === 'AVAILABLE' && !showAvailModel
          ? (<>
              {renderExtraLotMarketHomeInfo()}
              <div className={styles.floorplanMessage}>
              Choose a floorplan below...
            </div>
            </>
          ) : (
            <>
              {renderExtraLotMarketHomeInfo()}
              <div className={styles.modelSpecs}>
                {modelSpecs()}
              </div>
            </>
          )}
      </div>

      {activeMatterport && showMatterport()}
      {handleMenuFlow()}
      <button
        className={styles.backButton}
        onClick={() => handleBackClick()}
        type="button"
      >
        <img src={BackArrow} alt="back" />
      </button>
    </div>
  );
};

LotMenu.defaultProps = {
  showAvailModel: null,
};

LotMenu.propTypes = {
  clearSelectedLot: PropTypes.func.isRequired,
  lotType: PropTypes.string.isRequired,
  lotInfo: PropTypes.object.isRequired,
  handleOptionList: PropTypes.func.isRequired,
  showOptionList: PropTypes.bool.isRequired,
  activeMatterport:PropTypes.bool.isRequired,
  showAvailModel: PropTypes.string,
  handleShowAvailModel: PropTypes.func.isRequired,
  homesitesConfig: PropTypes.array.isRequired,
  activeModelData: PropTypes.array.isRequired,
  lotStatusConfig: PropTypes.object.isRequired,
  run2DIFPEvent: PropTypes.func.isRequired,
  setSelectedModel: PropTypes.func.isRequired,
  matterportConfig: PropTypes.object.isRequired,
  newModelData: PropTypes.object.isRequired
};

export default LotMenu;
